<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <b-button class="m-3" variant="primary" @click="getPrice">
          <div class="d-flex flex-column">
            <div style="font-size: 150px">
              <b-icon icon="calculator" />
            </div>
            <span class="my-5">Sincronizar Preços</span>
          </div>
        </b-button>
        <b-button class="m-3" variant="primary" @click="getStatus">
          <div class="d-flex flex-column">
            <div style="font-size: 150px">
              <b-icon icon="clipboard-check" />
            </div>
            <span class="my-5">Sincronizar Status</span>
          </div>
        </b-button>
        <b-button class="m-3" variant="primary" @click="getProduct">
          <div class="d-flex flex-column">
            <div style="font-size: 150px">
              <b-icon icon="card-checklist" />
            </div>
            <span class="my-5">Sincronizar Produtos</span>
          </div>
        </b-button>
        <b-button class="m-3" variant="primary" @click="getPackage">
          <div class="d-flex flex-column">
            <div style="font-size: 150px">
              <b-icon-box-seam />
            </div>
            <span class="my-5">Sincronizar Embalagem</span>
          </div>
        </b-button>
        <b-button class="m-3" variant="primary" @click="getWeight">
          <div class="d-flex flex-column">
            <div style="font-size: 150px">KG</div>
            <span class="my-5">Sincronizar Peso</span>
          </div>
        </b-button>
        <b-button class="m-3" variant="primary" @click="getStock">
          <div class="d-flex flex-column">
            <div style="font-size: 150px">
              <b-icon-columns-gap />
            </div>
            <span class="my-5">Sincronizar Estoque</span>
          </div>
        </b-button>
        <b-button class="m-3" variant="primary" @click="getInfo">
          <div class="d-flex flex-column">
            <div style="font-size: 150px" class="mb-5">
              <b-icon-card-text />
            </div>
            <span style="width: 187.5px" class="mb-4">
              Sincronizar Descrição e Referência
            </span>
          </div>
        </b-button>
        <b-button class="m-3" variant="primary" @click="getFinance">
          <div class="d-flex flex-column">
            <div style="font-size: 150px">
              <b-icon-cash />
            </div>
            <span class="my-5">Sincronizar Financeiro</span>
          </div>
        </b-button>
        <b-button class="m-3" variant="primary" disabled>
          <div class="d-flex flex-column">
            <div style="font-size: 150px">
              <b-icon-ui-checks />
            </div>
            <span class="my-5">Gerar Palavras Chave</span>
          </div>
        </b-button>
        <b-button class="m-3" variant="primary" disabled>
          <div class="d-flex flex-column">
            <div style="font-size: 150px">
              <b-icon-type />
            </div>
            <span class="my-5">Gerar Erros Ortográficos</span>
          </div>
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapMutations } from "vuex";
import http from "../plugins/http";

export default {
  name: "operations",
  methods: {
    ...mapMutations(["setToasted"]),
    async getPrice() {
      await http
        .get("/sync/price")
        .then(() => {
          this.setToasted({
            title: "Aguarde",
            message:
              "A sincronização de preço dos produtos foi solicitada com sucesso! Você recebera um email de confirmação em breve.",
            variant: "success",
            visible: true,
          });
        })
        .catch((e) => {
          this.setToasted({
            title: "ERRO",
            message: e.response.data.error,
            variant: "danger",
            visible: true,
          });
        });
    },
    async getProduct() {
      await http
        .get("/sync/product")
        .then(() => {
          this.setToasted({
            title: "Aguarde",
            message:
              "A sincronização de cadastro dos novos produtos foi solicitada com sucesso! Você recebera um email de confirmação em breve.",
            variant: "success",
            visible: true,
          });
        })
        .catch((e) => {
          this.setToasted({
            title: "ERRO",
            message: e.response.data.error,
            variant: "danger",
            visible: true,
          });
        });
    },
    async getStatus() {
      await http
        .get("/sync/status")
        .then(() => {
          this.setToasted({
            title: "Aguarde",
            message:
              "A sincronização de status dos produtos foi solicitada com sucesso! Você recebera um email de confirmação em breve.",
            variant: "success",
            visible: true,
          });
        })
        .catch((e) => {
          this.setToasted({
            title: "ERRO",
            message: e.response.data.error,
            variant: "danger",
            visible: true,
          });
        });
    },
    async getPackage() {
      await http
        .get("/sync/package")
        .then(() => {
          this.setToasted({
            title: "Aguarde",
            message:
              "A sincronização de status dos produtos foi solicitada com sucesso! Você recebera um email de confirmação em breve.",
            variant: "success",
            visible: true,
          });
        })
        .catch((e) => {
          this.setToasted({
            title: "ERRO",
            message: e.response.data.error,
            variant: "danger",
            visible: true,
          });
        });
    },
    async getWeight() {
      await http
        .get("/sync/weight")
        .then(() => {
          this.setToasted({
            title: "Aguarde",
            message:
              "A sincronização de peso dos produtos foi solicitada com sucesso! Você recebera um email de confirmação em breve.",
            variant: "success",
            visible: true,
          });
        })
        .catch((e) => {
          this.setToasted({
            title: "ERRO",
            message: e.response.data.error,
            variant: "danger",
            visible: true,
          });
        });
    },
    async getStock() {
      await http
        .get("/sync/stock")
        .then(() => {
          this.setToasted({
            title: "Aguarde",
            message:
              "A sincronização de estoque dos produtos foi solicitada com sucesso! Você recebera um email de confirmação em breve.",
            variant: "success",
            visible: true,
          });
        })
        .catch((e) => {
          this.setToasted({
            title: "ERRO",
            message: e.response.data.error,
            variant: "danger",
            visible: true,
          });
        });
    },
    async getInfo() {
      await http
        .get("/sync/info")
        .then(() => {
          this.setToasted({
            title: "Aguarde",
            message:
              "A sincronização de formações dos produtos foi solicitada com sucesso! Você recebera um email de confirmação em breve.",
            variant: "success",
            visible: true,
          });
        })
        .catch((e) => {
          this.setToasted({
            title: "ERRO",
            message: e.response.data.error,
            variant: "danger",
            visible: true,
          });
        });
    },
    async getFinance() {
      await http
        .get("/sync/finance")
        .then(() => {
          this.setToasted({
            title: "Aguarde",
            message:
              "A sincronização do financeiro do cliente foi solicitada com sucesso! Você recebera um email de confirmação em breve.",
            variant: "success",
            visible: true,
          });
        })
        .catch((e) => {
          this.setToasted({
            title: "ERRO",
            message: e.response.data.error,
            variant: "danger",
            visible: true,
          });
        });
    },
  },
};
</script>

<style lang="sass"></style>
